import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import CTALink from "../modules/cta"
import ScrollAnimation from "react-animate-on-scroll"

const Sitemap = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiBurgerMenu(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            sort
            main_menu
            link {
              slug
              link_type
              target_window
              manual_link
              strapi_parent {
                slug
                strapi_parent {
                  slug
                }
              }
            }
            sub_menu {
              label
              link {
                slug
                manual_link
                link_type
                target_window
                strapi_parent {
                  slug
                  strapi_parent {
                    slug
                  }
                }
              }
              child {
                label
                link {
                  slug
                  link_type
                  target_window
                  manual_link
                  strapi_parent {
                    slug
                    strapi_parent {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const menuData = data?.allStrapiBurgerMenu?.edges

  return (
    <div className="sitemap-component">
      <Layout>
        <Container>
          <div className="sitemap-section">
          <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
            <h1>Sitemap</h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={150} offset={100}>
            {menuData.length > 0 && (
              <ul className="primary">
                {menuData.map((item, i) => {
                  return (
                    <li className="primary-list">
                      <CTALink
                        link={item.node?.link}
                        title={item?.node?.main_menu}
                        target_window={item?.node?.link?.link_type}
                      />
                      {item.node?.sub_menu?.length > 0 && (
                        <ul className="secondary">
                          {item.node?.sub_menu.map((item, i) => {
                            return (
                              <li className="secondary-list">
                                <CTALink
                                  link={item?.link}
                                  title={item?.label}
                                  target_window={item?.link?.link_type}
                                  manual_link={item?.link?.manual_link}
                                />
                                {item?.child?.length > 0 && (
                                  <ul className="child">
                                    {item?.child?.map((item, i) => {
                                      return (
                                        <li className="child-list">
                                          <CTALink
                                            link={item?.link}
                                            title={item?.label}
                                            target_window={
                                              item?.link?.link_type
                                            }
                                            manual_link={
                                              item?.link?.manual_link
                                            }
                                          />
                                        </li>
                                      )
                                    })}
                                  </ul>
                                )}
                              </li>
                            )
                          })}
                        </ul>
                      )}
                    </li>
                  )
                })}
              </ul>
            )}
            </ScrollAnimation>
          </div>
        </Container>
      </Layout>
    </div>
  )
}

export default Sitemap
